var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "my-vehicle" } },
    [
      _c("HeaderNavigation", { attrs: { title: "Mon véhicule" } }),
      _c(
        "b-container",
        { staticClass: "main-container" },
        [
          _c("h1", [
            _vm._v(" " + _vm._s(_vm.vehicle.displayValue) + " "),
            _c(
              "button",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_c("span", [_vm._v("×")])]
            )
          ]),
          _c(
            "section",
            [
              _c("h2", [_vm._v("Informations du véhicule")]),
              _c("VehicleResume", { attrs: { vehicle: _vm.vehicle } }),
              _c(
                "footer",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { variant: "dark" }
                    },
                    [_vm._v("MODIFIER LES INFORMATIONS")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "section",
            [
              _c(
                "h2",
                [
                  _vm._v(" Photo de la plaque d'immatriculation "),
                  _c(
                    "b-badge",
                    {
                      staticClass: "float-right",
                      attrs: { variant: _vm.badgeVariant }
                    },
                    [_vm._v(_vm._s(_vm.validationLabel))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "text-center" }, [
                    _c("img", {
                      staticClass: "registration-doc",
                      attrs: { src: _vm.photoUrl }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "loader",
                          rawName: "v-loader.disable",
                          value: _vm.deleting,
                          expression: "deleting",
                          modifiers: { disable: true }
                        }
                      ],
                      staticClass: "delete",
                      attrs: { variant: "warning" },
                      on: { click: _vm.onDeleteVehicle }
                    },
                    [_vm._v(" Supprimer ce véhicule")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }