<template>
  <div class="v-resume">
    <b-row>
      <b-col cols="6">
        <b-form-group label="MARQUE">
          <span class="text-uppercase">{{ specs.brand }}</span>
        </b-form-group>
        <b-form-group label="MODELE">
          <span class="text-uppercase">{{ specs.label }}</span>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="AUTONOMIE">
          <span class="text-uppercase">{{ specs.version.autonomyKM }} KM</span>
        </b-form-group>
        <b-form-group label="PUISSANCE BATTERIE">
          <span class="text-uppercase"
            >{{ specs.version.batteryPowerKWH }} KWH</span
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label="PLAQUE">
          <span class="text-uppercase">{{ vehicle.plateNumber }}</span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'vehicle-resume',
  props: {
    vehicle: { type: Object, required: true }
  },
  computed: {
    specs() {
      const specs = this.$store.getters.getVehicleSpecifications(
        this.vehicle.vehicleRowKey
      )

      return (
        specs || {
          brand: '',
          label: '',
          version: {
            autonomyKM: undefined,
            batteryPowerKWH: undefined
          }
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '@/styles.scss';

.v-resume {
  font-weight: $font-weight-bold;
  font-size: 9px;
  color: $dark;

  fieldset.form-group {
    margin-bottom: 0;

    legend.col-form-label {
      color: #a0a0a0;
      padding-bottom: 0;
      font-size: 9px !important;
    }
  }

  .price {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;

    &::before {
      display: inline-block;
      padding: 0px 5px 0px 0px;
      vertical-align: -2px;
      content: url('../../assets/img/icon-yellow-plus-sign.png');
    }
  }
}
</style>
