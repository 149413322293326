<template>
  <div id="my-vehicle">
    <HeaderNavigation title="Mon véhicule" />
    <b-container class="main-container">
      <h1>
        {{ vehicle.displayValue }}
        <button class="close" @click="$router.back()">
          <span>&times;</span>
        </button>
      </h1>
      <section>
        <h2>Informations du véhicule</h2>
        <VehicleResume :vehicle="vehicle" />
        <footer class="text-right">
          <!-- TODO: Allow modification -->
          <b-button variant="dark" v-show="false"
            >MODIFIER LES INFORMATIONS</b-button
          >
        </footer>
      </section>
      <section>
        <h2>
          Photo de la plaque d'immatriculation
          <b-badge :variant="badgeVariant" class="float-right">{{
            validationLabel
          }}</b-badge>
        </h2>
        <b-row>
          <b-col class="text-center">
            <img :src="photoUrl" class="registration-doc" />
          </b-col>
        </b-row>
      </section>
      <b-row>
        <b-col class="text-center">
          <b-button
            v-loader.disable="deleting"
            class="delete"
            variant="warning"
            @click="onDeleteVehicle"
          >
            Supprimer ce véhicule</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import VehicleResume from '@/components/Vehicles/VehicleResume.vue'
import { Routes } from '@/router.js'

export default {
  name: 'my-vehicle',
  props: {
    id: { type: String, required: true }
  },
  components: { HeaderNavigation, VehicleResume },
  data() {
    return {
      deleting: false
    }
  },
  computed: {
    vehicle() {
      const v = this.$store.getters.getVehicle(this.id)

      return (
        v || {
          displayValue: ''
        }
      )
    },
    isValid() {
      return this.vehicle.registrationCertificate
        ? this.vehicle.registrationCertificate.isValid
        : undefined
    },
    badgeVariant() {
      return this.isValid ? 'success' : 'warning'
    },
    validationLabel() {
      return this.isValid === undefined
        ? 'En cours de validation'
        : this.isValid
        ? 'Validée'
        : 'Invalide'
    },
    photoUrl() {
      return this.vehicle && this.vehicle.registrationCertificate
        ? this.vehicle.registrationCertificate.url
        : undefined
    }
  },
  methods: {
    ...mapActions(['loadVehicle', 'deleteVehicle']),
    onDeleteVehicle() {
      this.deleting = true

      this.deleteVehicle({ api: this.$apiService, vId: this.vehicle.id })
        .then(() => this.$router.push(Routes.ACCOUNT))
        .catch(err =>
          console.error('Uncaught error while deleteing vehicle: ', err)
        )
        .finally(() => (this.deleting = false))
    }
  },
  async mounted() {
    this.loadVehicle({ api: this.$apiService, id: this.id })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-vehicle {
  height: 100vh;
  background-color: #f8f8f8;

  h1,
  h2 {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  h1 {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;

    button.close {
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: #152536;
      opacity: 1;
    }
  }

  section {
    margin-bottom: 20px;

    h2 {
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
      color: #cecece;
    }

    .registration-doc {
      width: 300px;
      height: auto;
      border-radius: 10px;
    }
  }

  .btn.delete {
    width: 216px;
    letter-spacing: 0.65px;
    line-height: 12px;
  }
}
</style>
