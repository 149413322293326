var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-resume" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "MARQUE" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.specs.brand))
                ])
              ]),
              _c("b-form-group", { attrs: { label: "MODELE" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.specs.label))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "AUTONOMIE" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.specs.version.autonomyKM) + " KM")
                ])
              ]),
              _c("b-form-group", { attrs: { label: "PUISSANCE BATTERIE" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.specs.version.batteryPowerKWH) + " KWH")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "PLAQUE" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.vehicle.plateNumber))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }